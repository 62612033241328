package com.mshopsas.enterprise.aiw.scan.screens.search

import cafe.adriel.voyager.core.model.screenModelScope
import co.touchlab.kermit.Logger
import com.benasher44.uuid.uuid4
import com.mshopsas.enterprise.aiw.scan.data.Repository
import com.mshopsas.enterprise.aiw.scan.data.basket.Basket
import com.mshopsas.enterprise.aiw.scan.data.basket.Cell
import com.mshopsas.enterprise.aiw.scan.data.basket.Item
import com.mshopsas.enterprise.aiw.scan.data.basket.details.ProductDetail
import com.mshopsas.enterprise.aiw.scan.network.Resource
import com.mshopsas.enterprise.aiw.scan.network.request.CheckInRequest
import com.mshopsas.enterprise.aiw.scan.network.response.BasketStatusResponse
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.BaseScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.home.HomeContract
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.launch

class SearchProductScreenModel(
    private val repository: Repository
) : BaseScreenModel<SearchProductContract.Event, SearchProductContract.State, SearchProductContract.Effect>() {

    override fun setInitialState() = SearchProductContract.State(
        isLoading = false,
        products = listOf(),
        isAnonymous = false
    )

    override fun handleEvents(event: SearchProductContract.Event) {
        when (event) {
            is SearchProductContract.Event.OnBack -> onBack()
            is SearchProductContract.Event.OnSearch -> onSearch(event.text)
            is SearchProductContract.Event.OnClick -> onClick(event.product)
        }
    }

    init {
        initialize()
    }

    private fun initialize() {
        screenModelScope.launch {
            repository.getBasket().collect { basket ->
                setState {
                    copy(
                        isAnonymous = basket.isAnonymous()
                    )
                }
            }
        }
    }

    private fun onBack() {
        setEffect { SearchProductContract.Effect.Navigation.ToBack }
    }

    private fun onSearch(text: String) {
        if(text.length < 2) {
            setState { copy(products = listOf()) }
        } else {
            setState { copy(isLoading = true) }
            screenModelScope.launch {
                repository.callProductSearch(text).collect { result ->
                    when (result.status) {
                        Resource.Status.SUCCESS -> {
                            setState { copy(
                                isLoading = false,
                                products = result.data?.productList ?: listOf()
                            ) }
                        }

                        Resource.Status.ERROR -> {
                            setState { copy(isLoading = false) }
                        }

                        Resource.Status.LOADING -> {
                            setState { copy(isLoading = true) }
                        }
                    }
                }
            }
        }
    }

    private fun onClick(product: ProductDetail) {
        setEffect { SearchProductContract.Effect.Navigation.ToProductDetail(product) }
    }

}
