package com.mshopsas.enterprise.aiw.scan.di

import androidx.compose.runtime.Composable
import cafe.adriel.voyager.core.model.ScreenModel
import cafe.adriel.voyager.core.model.rememberScreenModel
import cafe.adriel.voyager.core.screen.Screen
import co.touchlab.kermit.koin.KermitKoinLogger
import com.mshopsas.enterprise.aiw.scan.data.Persistence
import com.mshopsas.enterprise.aiw.scan.data.PersistenceService
import com.mshopsas.enterprise.aiw.scan.data.Repository
import com.mshopsas.enterprise.aiw.scan.data.RepositoryImpl
import com.mshopsas.enterprise.aiw.scan.screens.audit.AuditScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.historic.HistoryOrderScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.historic.detail.HistoryOrderDetailScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.home.HomeScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.payment.PaymentSelectionScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.payment.ticket.TicketScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.search.SearchProductScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.search.detail.SearchProductDetailScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.guide.GuideScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.intro.IntroScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.loyalty.LoyaltyScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.permission.CameraPermissionScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.permission.denied.CameraPermissionDeniedScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.store.camera.CameraScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.store.location.LocationScreenModel
import com.mshopsas.enterprise.aiw.scan.utils.player.MediaPlayerController
import io.github.xxfast.kstore.KStore
import io.ktor.client.HttpClient
import io.ktor.client.plugins.HttpTimeout
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.defaultRequest
import io.ktor.client.plugins.logging.LogLevel
import io.ktor.client.plugins.logging.Logger
import io.ktor.client.plugins.logging.Logging
import io.ktor.client.request.header
import io.ktor.http.ContentType
import io.ktor.http.HttpHeaders
import io.ktor.http.URLBuilder
import io.ktor.http.takeFrom
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.json.Json
import org.koin.core.context.startKoin
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf
import org.koin.core.parameter.ParametersDefinition
import org.koin.core.qualifier.Qualifier
import org.koin.dsl.module
import org.koin.mp.KoinPlatform.getKoin

val httpClientModule = module {
    single<HttpClient> {
        HttpClient {
            defaultRequest {
                url.takeFrom(URLBuilder().takeFrom("https://back.micompra.club/"))
//                url.takeFrom(URLBuilder().takeFrom("https://eroski.mshop.cloud/"))
//                url.takeFrom(URLBuilder().takeFrom("http://localhost:8080/"))
                header(HttpHeaders.ContentType, ContentType.Application.Json)
            }
            install(HttpTimeout) {
                requestTimeoutMillis = 15_000
            }
            install(ContentNegotiation) {
                json(Json {
                    ignoreUnknownKeys = true
                    prettyPrint = true
                    isLenient = true
                })
            }
            install(Logging) {
                level = LogLevel.ALL
                logger = object : Logger {
                    override fun log(message: String) {
                        co.touchlab.kermit.Logger.withTag("ktor").d(message)
                    }
                }
            }
        }
    }
}

val repositoryModule = module {
    single<Repository> {
        RepositoryImpl(
            get<HttpClient>(),
            get<PersistenceService>(),
            get<MediaPlayerController>()
        )
    }
}

val screenModelsModule = module {
    factoryOf(::IntroScreenModel)
    factoryOf(::GuideScreenModel)
    factoryOf(::LocationScreenModel)
    factoryOf(::CameraScreenModel)
    factoryOf(::CameraPermissionScreenModel)
    factoryOf(::CameraPermissionDeniedScreenModel)
    factoryOf(::LoyaltyScreenModel)
    factoryOf(::HomeScreenModel)
    factoryOf(::PaymentSelectionScreenModel)
    factoryOf(::TicketScreenModel)
    factoryOf(::AuditScreenModel)
    factoryOf(::SearchProductScreenModel)
    factoryOf(::SearchProductDetailScreenModel)
    factoryOf(::HistoryOrderScreenModel)
    factoryOf(::HistoryOrderDetailScreenModel)
}

expect val dataPersistenceModule: Module

val persistenceModule = module {
    single {
        val get = get<KStore<Persistence>>()
        PersistenceService(get)
    }
}

expect val mediaPlayerModule: Module

@Composable
inline fun <reified T : ScreenModel> Screen.getScreenModel(
    qualifier: Qualifier? = null,
    noinline parameters: ParametersDefinition? = null
): T {
    val koin = getKoin()
    return rememberScreenModel(tag = qualifier?.value) { koin.get(qualifier, parameters) }
}

fun initKoin() {
    startKoin {
        logger(KermitKoinLogger(co.touchlab.kermit.Logger))
        modules(
            httpClientModule,
            repositoryModule,
            screenModelsModule,
            dataPersistenceModule,
            persistenceModule,
            mediaPlayerModule,
        )
    }
}
