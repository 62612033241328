package com.mshopsas.enterprise.aiw.scan.screens.home.composables

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.home_aisle
import aiwscan.composeapp.generated.resources.home_aisle_order
import aiwscan.composeapp.generated.resources.product_in_list
import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.LocationOn
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mshopsas.enterprise.aiw.scan.data.basket.Item
import com.mshopsas.enterprise.aiw.scan.data.basket.details.ProductDetail
import com.mshopsas.enterprise.aiw.scan.screens.commons.AutoResizeText
import com.mshopsas.enterprise.aiw.scan.screens.commons.FontSizeRange
import com.mshopsas.enterprise.aiw.scan.screens.commons.ImageLoader
import com.mshopsas.enterprise.aiw.scan.screens.home.composables.Price
import com.mshopsas.enterprise.aiw.scan.screens.home.composables.toMoney
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun ProductRelatedMiniItem(product: ProductDetail, onItemClick: (ProductDetail) -> Unit) {
    Row(
        modifier = Modifier.fillMaxWidth().clickable {
            onItemClick(product)
        }
    ) {

        Box(modifier = Modifier.weight(1 / 4f).aspectRatio(4 / 4f)) {
            ImageLoader(product.imageMainName ?: "")
        }

        Column(
            modifier = Modifier.weight(3 / 4f).aspectRatio(3 / 1f)
                .padding(start = 16.dp, end = 16.dp, top = 8.dp, bottom = 8.dp),
            verticalArrangement = Arrangement.spacedBy(4.dp)
        ) {
            Text(
                text = product.nameLine1,
                style = MaterialTheme.typography.titleMedium,
                fontSize = 16.sp,
                fontWeight = FontWeight.Bold,
                modifier = Modifier.weight(1f),
                maxLines = 2
            )
            Text(
                text = product.nameLine2 ?: "",
                style = MaterialTheme.typography.titleMedium,
                fontSize = 14.sp,
                fontWeight = FontWeight.Normal,
                modifier = Modifier.weight(1f),
                maxLines = 1
            )

            if (product.aisle != null) {
                Row {
                    Image(Icons.Default.LocationOn, null)
                    Spacer(modifier = Modifier.width(4.dp))
                    Text(stringResource(Res.string.home_aisle, product.aisle), fontSize = 22.sp)
                    if(product.aisleOrder != null) {
                        Text(stringResource(Res.string.home_aisle_order, product.aisleOrder), fontSize = 22.sp)
                    }
                }
            }

            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.Bottom,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Price(product.finalUnitPrice ?: 0, false)
//                Row {
//                    Spacer(modifier = Modifier.width(4.dp))
//                    Text(
//                        text = product.labelMeasureUnitPrice ?: "",
//                        fontSize = 24.sp
//                    )
//                }
            }
        }
    }
}
