package com.mshopsas.enterprise.aiw.scan.screens.search

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.home_menu_power_by_1
import aiwscan.composeapp.generated.resources.home_menu_search
import aiwscan.composeapp.generated.resources.intro_message
import aiwscan.composeapp.generated.resources.intro_next
import aiwscan.composeapp.generated.resources.intro_welcome
import aiwscan.composeapp.generated.resources.welcome
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Clear
import androidx.compose.material.icons.filled.Menu
import androidx.compose.material.icons.filled.Search
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.KeyEvent
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onKeyEvent
import androidx.compose.ui.platform.LocalSoftwareKeyboardController
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mshopsas.enterprise.aiw.scan.data.basket.details.ProductDetail
import com.mshopsas.enterprise.aiw.scan.screens.commons.BackButton
import com.mshopsas.enterprise.aiw.scan.screens.commons.BackButtonLight
import com.mshopsas.enterprise.aiw.scan.screens.commons.ButtonDef
import com.mshopsas.enterprise.aiw.scan.screens.commons.MScanScaffold
import com.mshopsas.enterprise.aiw.scan.screens.commons.ProgressIndicator
import com.mshopsas.enterprise.aiw.scan.screens.commons.SearchButton
import com.mshopsas.enterprise.aiw.scan.screens.home.HomeContract
import com.mshopsas.enterprise.aiw.scan.screens.home.composables.CellView
import com.mshopsas.enterprise.aiw.scan.screens.search.composables.ProductSearchCellView
import com.mshopsas.enterprise.aiw.scan.theme.BlueGrey
import com.mshopsas.enterprise.aiw.scan.theme.RedDark
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.stringResource
import org.jetbrains.compose.resources.vectorResource

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun SearchProductView(
    state: SearchProductContract.State,
    onEventSent: (SearchProductContract.Event) -> Unit
) {

    val keyboardController = LocalSoftwareKeyboardController.current
    var text by remember { mutableStateOf("") }
    val trailingIconView = @Composable {
        IconButton(
            onClick = { text = "" },
        ) {
            Icon(
                Icons.Default.Clear,
                contentDescription = "",
                tint = Color.Black
            )
        }
    }

    MScanScaffold(
        navigationIcon = {
            BackButton( onClick= {onEventSent(SearchProductContract.Event.OnBack)})
        }
    ) { padding ->
        Column(modifier = Modifier.fillMaxSize().padding(padding)) {
            Row(modifier = Modifier.fillMaxWidth().padding(8.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                TextField(
                    value = text,
                    singleLine = true,
                    placeholder = { Text(stringResource(Res.string.home_menu_search)) },
                    trailingIcon = trailingIconView,
                    onValueChange = { text = it },
                    keyboardOptions = KeyboardOptions.Default.copy(imeAction = ImeAction.Search),
                    keyboardActions = KeyboardActions(onSearch = {
                        onEventSent(SearchProductContract.Event.OnSearch(text))
                        keyboardController?.hide()
                    }),
                    modifier = Modifier.weight(1f).onKeyEvent {
                        return@onKeyEvent if(it.key.keyCode == Key.Enter.keyCode) {
                            onEventSent(SearchProductContract.Event.OnSearch(text))
                            keyboardController?.hide()
                            true
                        } else {
                            false
                        }
                    }
                )
                SearchButton(
                    BlueGrey,
                    Color.White,
                    onClick = { onEventSent(SearchProductContract.Event.OnSearch(text)) }
                )
            }
            Column(
                modifier = Modifier.background(Color.White).fillMaxSize()
            ) {
                LazyColumn(modifier = Modifier.fillMaxSize()) {
                    items(state.products) { product ->
                    ProductSearchCellView(
                        product = product,
                        isAnonymous = state.isAnonymous,
                        onItemClick = {
                            onEventSent(SearchProductContract.Event.OnClick(it))
                        },
                        modifier = Modifier.animateItemPlacement()
                    )}
                }
            }
        }
    }

    if (state.isLoading) {
        ProgressIndicator()
    }
}
