package com.mshopsas.enterprise.aiw.scan.screens.home

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.barcode_scanner
import aiwscan.composeapp.generated.resources.home_items
import aiwscan.composeapp.generated.resources.home_menu_basket
import aiwscan.composeapp.generated.resources.home_menu_brochures
import aiwscan.composeapp.generated.resources.home_menu_historic
import aiwscan.composeapp.generated.resources.home_menu_power_by_1
import aiwscan.composeapp.generated.resources.home_menu_power_by_2
import aiwscan.composeapp.generated.resources.home_menu_search
import aiwscan.composeapp.generated.resources.home_menu_turns
import aiwscan.composeapp.generated.resources.home_pay
import aiwscan.composeapp.generated.resources.home_payment_message
import aiwscan.composeapp.generated.resources.home_payment_ok
import aiwscan.composeapp.generated.resources.home_payment_title
import aiwscan.composeapp.generated.resources.home_subtotal
import aiwscan.composeapp.generated.resources.home_unknown_product_message
import aiwscan.composeapp.generated.resources.home_unknown_product_title
import aiwscan.composeapp.generated.resources.home_scan_loyalty
import aiwscan.composeapp.generated.resources.home_comparing_with
import androidx.compose.animation.AnimatedContent
import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.MenuBook
import androidx.compose.material.icons.filled.Checklist
import androidx.compose.material.icons.filled.Clear
import androidx.compose.material.icons.filled.Menu
import androidx.compose.material.icons.filled.Search
import androidx.compose.material.icons.filled.ShoppingBasket
import androidx.compose.material.icons.filled.TurnedIn
import androidx.compose.material.icons.outlined.Visibility
import androidx.compose.material.ripple.LocalRippleTheme
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.DrawerState
import androidx.compose.material3.DrawerValue
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.FloatingActionButtonDefaults
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.LinearProgressIndicator
import androidx.compose.material3.ModalDrawerSheet
import androidx.compose.material3.ModalNavigationDrawer
import androidx.compose.material3.NavigationDrawerItem
import androidx.compose.material3.NavigationDrawerItemDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.rememberDrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.zIndex
import com.mshopsas.enterprise.aiw.scan.screens.commons.ButtonDef
import com.mshopsas.enterprise.aiw.scan.screens.commons.LightRippleTheme
import com.mshopsas.enterprise.aiw.scan.screens.commons.MScanScaffold
import com.mshopsas.enterprise.aiw.scan.screens.commons.ProgressIndicator
import com.mshopsas.enterprise.aiw.scan.screens.commons.okCancelBottomSheet.OkCancelBottomSheet
import com.mshopsas.enterprise.aiw.scan.screens.home.composables.CellView
import com.mshopsas.enterprise.aiw.scan.screens.home.composables.EditQuantityBottomSheet
import com.mshopsas.enterprise.aiw.scan.screens.home.composables.EmptyDB
import com.mshopsas.enterprise.aiw.scan.screens.home.composables.MovableFloatingActionButton
import com.mshopsas.enterprise.aiw.scan.screens.home.composables.PromoBottomSheet
import com.mshopsas.enterprise.aiw.scan.screens.home.composables.toMoney
import com.mshopsas.enterprise.aiw.scan.theme.BlueGrey
import com.mshopsas.enterprise.aiw.scan.theme.GreenMShop
import com.mshopsas.enterprise.aiw.scan.theme.RedDark
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.stringResource
import org.jetbrains.compose.resources.vectorResource

@OptIn(ExperimentalMaterial3Api::class, ExperimentalFoundationApi::class)
@Composable
fun HomeView(
    state: HomeContract.State,
    onEventSent: (HomeContract.Event) -> Unit
) {

    val drawerState: DrawerState = rememberDrawerState(initialValue = DrawerValue.Closed)
    val coroutineScope = rememberCoroutineScope()

    val originalDensity = LocalDensity.current
    val requiredContainerWidth = 300.dp
    val manipulatedDensity = (requiredContainerWidth.value * originalDensity.density / 360.dp.value)

    CompositionLocalProvider(LocalDensity.provides(Density(manipulatedDensity))) {
        ModalNavigationDrawer(

            drawerState = drawerState,
            drawerContent = {
                CompositionLocalProvider(LocalDensity provides originalDensity) {
                    ModalDrawerSheet(
                        drawerContainerColor = Color.White,
                        modifier = Modifier.width(requiredContainerWidth)
                    ) {

                        Text(state.userName, modifier = Modifier.padding(16.dp))

                        HorizontalDivider()

                        CompositionLocalProvider(LocalRippleTheme provides LightRippleTheme) {
                            NavigationDrawerItem(
                                modifier = Modifier.padding(8.dp),
                                label = { Text(text = stringResource(Res.string.home_menu_basket)) },
                                icon = { Icon(Icons.Default.ShoppingBasket, null) },
                                selected = true,
                                shape = RoundedCornerShape(4.dp),
                                onClick = {
                                    coroutineScope.launch {
                                        drawerState.close()
                                    }
                                },
                                colors = NavigationDrawerItemDefaults.colors(
                                    selectedContainerColor = BlueGrey,
                                    selectedIconColor = Color.White,
                                    selectedTextColor = Color.White
                                )
                            )
                        }

                        NavigationDrawerItem(
                            modifier = Modifier.padding(8.dp),
                            label = { Text(text = stringResource(Res.string.home_menu_search)) },
                            icon = { Icon(Icons.Default.Search, null) },
                            selected = false,
                            shape = RoundedCornerShape(4.dp),
                            onClick = {
                                coroutineScope.launch {
                                    drawerState.close()
                                    onEventSent(HomeContract.Event.OnSearchClick)
                                }
                            }
                        )

                        NavigationDrawerItem(
                            modifier = Modifier.padding(8.dp),
                            label = { Text(text = stringResource(Res.string.home_menu_turns)) },
                            icon = { Icon(Icons.Default.TurnedIn, null) },
                            selected = false,
                            shape = RoundedCornerShape(4.dp),
                            onClick = {
                                coroutineScope.launch {
                                    drawerState.close()
                                }
                            }
                        )

//                        AnimatedVisibility(visible = state.hasBrochure) {
                            NavigationDrawerItem(
                                modifier = Modifier.padding(8.dp),
                                label = { Text(text = stringResource(Res.string.home_menu_brochures)) },
                                icon = { Icon(Icons.AutoMirrored.Filled.MenuBook, null) },
                                selected = false,
                                shape = RoundedCornerShape(4.dp),

                                onClick = {
                                    coroutineScope.launch {
                                        drawerState.close()
                                        onEventSent(HomeContract.Event.OnBrochureClick)
                                    }
                                }
                            )
//                        }

                        AnimatedVisibility(visible = !state.isAnonymous) {
                            NavigationDrawerItem(
                                modifier = Modifier.padding(8.dp),
                                label = { Text(text = stringResource(Res.string.home_menu_historic)) },
                                icon = { Icon(Icons.Default.Checklist, null) },
                                selected = false,
                                shape = RoundedCornerShape(4.dp),

                                onClick = {
                                    coroutineScope.launch {
                                        drawerState.close()
                                        onEventSent(HomeContract.Event.OnHistoricClick)
                                    }
                                }
                            )
                        }
                        Box(
                            modifier = Modifier.fillMaxSize(),
                            contentAlignment = Alignment.BottomStart
                        ) {
                            /*
                            Column {

                                FlagSelector(
                                    langList = listOf("en", "es", "eu", "fr", "it"),
                                    currentLang = "es",
                                    onClick = { lang ->
                                        Logger.i { "Lang clicked: $lang" }
                                        coroutineScope.launch {
                                            drawerState.close()
                                        }
                                    },
                                    modifier = Modifier.fillMaxWidth()
                                )

                                Spacer(modifier = Modifier.height(16.dp))
                                */

                            Row(
                                modifier = Modifier.padding(16.dp)
                            ) {
                                Text(
                                    text = stringResource(Res.string.home_menu_power_by_1),
                                    fontWeight = FontWeight.Medium
                                )
                                Text(
                                    text = stringResource(Res.string.home_menu_power_by_2),
                                    color = GreenMShop,
                                    fontWeight = FontWeight.Bold
                                )
                            }
                            //}
                        }
                    }
                }
            }
        ) {

            MScanScaffold(
                navigationIcon = {
                    IconButton(
                        onClick = { coroutineScope.launch { drawerState.open() } }
                    ) {
                        Icon(
                            imageVector = Icons.Default.Menu,
                            contentDescription = null,
                            tint = Color.White
                        )
                    }
                },
                /*topBar = {
                TopAppBar(
                    colors = TopAppBarDefaults.topAppBarColors().copy(containerColor = RedDark),
                    navigationIcon = {
                        IconButton(onClick = {
                            coroutineScope.launch {
                                drawerState.open()
                            }
                        }) {
                            Icon(
                                imageVector = Icons.Default.Menu,
                                contentDescription = null
                            )
                        }
                    },
                    title = {
                        Image(
                            painterResource(Res.drawable.mscan_logo),
                            null,
                            modifier = Modifier.fillMaxWidth(),
                            contentScale = ContentScale.Inside
                        )
                    },
                    actions = {
                        BadgedBox(
                            badge = {
                                if (state.count > 0) {
                                    Badge(
                                        modifier = Modifier.padding(end = 4.dp),
                                        containerColor = RedDark,
                                        contentColor = Color.White
                                    ) {
                                        Text(text = "${state.count}")
                                    }
                                }
                            }
                        ) {
                            IconButton(onClick = {
                                // onEventSent(HomeContract.Event.OnNextClick)
                            }) {
                                Icon(
                                    imageVector = Icons.Filled.ShoppingBasket,
                                    contentDescription = "Shopping cart",
                                )
                            }
                        }

                    }
                )
            },*/
                floatingActionButton = {
                    MovableFloatingActionButton(
                        onClick = { onEventSent(HomeContract.Event.OnNextClick) },
                        modifier = Modifier.size(72.dp),
                        containerColor = BlueGrey,
                        shape = CircleShape,
                        elevation = FloatingActionButtonDefaults.elevation(defaultElevation = 2.dp)
                    ) {
                        Icon(
                            imageVector = vectorResource(Res.drawable.barcode_scanner),
                            modifier = Modifier.size(32.dp),
                            contentDescription = null,
                            tint = Color.White
                        )
                    }
                }
            ) { padding ->
                Column(
                    modifier = Modifier
                        .background(Color.White)
                        .fillMaxSize()
                        .padding(padding),
                ) {
                    AnimatedContent(state.isAnonymous) { anonymous ->
                        if (anonymous) {
                            Row(
                                modifier = Modifier.fillMaxWidth()
                            ) {
                                Box(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .background(BlueGrey)
                                        .padding(6.dp),
                                    contentAlignment = Alignment.TopCenter
                                ) {
                                    Text(
                                        text = stringResource(Res.string.home_scan_loyalty),
                                        fontSize = 20.sp,
                                        fontWeight = FontWeight.Bold,
                                        color = Color.White
                                    )
                                }
                            }
                        }
                    }

                    AnimatedContent(state.hasCompareList) { hasCompareList ->
                        if (hasCompareList) {
                            Column(
                                modifier = Modifier.background(RedDark).wrapContentSize()
                            ) {
                                Text(
                                    modifier = Modifier.padding(start = 16.dp, end = 8.dp),
                                    text = stringResource(Res.string.home_comparing_with, "Lista"),
                                    fontSize = 16.sp,
                                    fontWeight = FontWeight.Normal,
                                    color = Color.White
                                )
                                Row(
                                    modifier = Modifier.fillMaxWidth()
                                        .background(RedDark)
                                        .padding(0.dp, 3.dp, 0.dp, 3.dp),
                                    verticalAlignment = Alignment.CenterVertically
                                ) {
                                    IconButton(
                                        modifier = Modifier.height(42.dp).weight(1 / 6f),
                                        onClick = {
                                            onEventSent(HomeContract.Event.OnShowCompareListClick)
                                        }
                                    ) {
                                        Icon(
                                            imageVector = Icons.Outlined.Visibility,
                                            contentDescription = null,
                                            tint = Color.White
                                        )
                                    }
                                    LinearProgressIndicator(
                                        progress = { state.compareProgress },
                                        modifier = Modifier.weight(4 / 6f),
                                        color = BlueGrey,
                                        trackColor = Color.White)
                                    IconButton(
                                        modifier = Modifier.height(42.dp).weight(1 / 6f),
                                        onClick = {
                                            onEventSent(HomeContract.Event.OnClearCompareListClick)
                                        }
                                    ) {
                                        Icon(
                                            imageVector = Icons.Default.Clear,
                                            contentDescription = null,
                                            tint = Color.White
                                        )
                                    }
                                }
                            }
                        }
                    }

//                    AnimatedContent(state.error.isNotEmpty()) {
//                        Text(text = state.error, color = RedDark)
//                    }

                    AnimatedContent(state.items.isNotEmpty()) { objectAvailable ->
                        if (objectAvailable) {
                            Column(
                                modifier = Modifier.background(Color.White).fillMaxSize()
                            ) {
                                Row(
                                    modifier = Modifier.fillMaxWidth()
                                        .shadow(3.dp)
                                        .zIndex(1F)
                                        .background(RedDark)
                                        .padding(horizontal = 16.dp, vertical = 4.dp)
                                ) {
                                    Column(modifier = Modifier.weight(2f / 3f)) {
                                        Row {
                                            Text(
                                                text = "${state.count}",
                                                fontWeight = FontWeight.Bold,
                                                fontSize = 18.sp,
                                                color = Color.White
                                            )
                                            Spacer(modifier = Modifier.width(4.dp))
                                            Text(
                                                text = stringResource(Res.string.home_items),
                                                fontSize = 18.sp,
                                                color = Color.White
                                            )
                                        }

                                        Row {
                                            Text(
                                                text = stringResource(Res.string.home_subtotal),
                                                fontSize = 18.sp,
                                                color = Color.White
                                            )
                                            Spacer(modifier = Modifier.width(4.dp))
                                            Text(
                                                text = state.total.toMoney(),
                                                fontWeight = FontWeight.Bold,
                                                fontSize = 18.sp,
                                                color = Color.White
                                            )
                                        }
                                    }

                                    CompositionLocalProvider(LocalRippleTheme provides LightRippleTheme) {
                                        ButtonDef(
                                            onClick = {
                                                onEventSent(HomeContract.Event.OnPayClick)
                                            },
                                            colors = ButtonDefaults.buttonColors(containerColor = BlueGrey),
                                            modifier = Modifier.weight(1f / 3f),
                                        ) {
                                            Text(
                                                text = stringResource(Res.string.home_pay),
                                                color = Color.White,
                                                fontWeight = FontWeight.Bold
                                            )
                                        }
                                    }
                                }

                                LazyColumn(modifier = Modifier.fillMaxSize()) {
                                    items(state.items) { cell ->
                                        CellView(
                                            cell = cell,
                                            isAnonymous = state.isAnonymous,
                                            onItemClick = {
                                                onEventSent(
                                                    HomeContract.Event.OnItemClick(
                                                        it
                                                    )
                                                )
                                            },
                                            modifier = Modifier.animateItemPlacement()
                                        )
                                    }
                                }
                            }
                        } else {
                            EmptyDB(padding)
                        }
                    }
                }
            }

            if (state.isLoading) {
                ProgressIndicator()
            }

            if (state.editQuantityItem != null) {
                EditQuantityBottomSheet(
                    item = state.editQuantityItem,
                    detailPromo = state.editQuantityItemDetailPromo,
                    offProduct = state.editQuantityItemOff,
                    related = state.related,
                    onOK = {
                        onEventSent(HomeContract.Event.OnEditQuantityOk(it))
                    },
                    onCancel = {
                        onEventSent(HomeContract.Event.OnEditQuantityCancel)
                    }
                )
            }

            if (state.unknownProduct != null) {
                OkCancelBottomSheet(
                    title = stringResource(Res.string.home_unknown_product_title),
                    message = stringResource(
                        Res.string.home_unknown_product_message,
                        state.unknownProduct
                    ),
                    onOK = { onEventSent(HomeContract.Event.OnUnknownClose) },
                    onDismiss = { onEventSent(HomeContract.Event.OnUnknownClose) }
                )
            }

            if (state.showPaymentSheet) {
                OkCancelBottomSheet(
                    title = stringResource(Res.string.home_payment_title),
                    message = stringResource(Res.string.home_payment_message),
                    okText = stringResource(Res.string.home_payment_ok),
                    onOK = { onEventSent(HomeContract.Event.OnPaymentPerform) },
                    onCancel = { onEventSent(HomeContract.Event.OnPaymentClose) },
                    onDismiss = { onEventSent(HomeContract.Event.OnPaymentClose) }
                )
            }

            if (state.itemInPromo != null) {
                PromoBottomSheet(
                    item = state.itemInPromo,
                    detailPromo = state.itemInPromoPromo,
                    onDismiss = { onEventSent(HomeContract.Event.OnPromoClose) })
            }
        }
    }
}
