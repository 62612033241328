package com.mshopsas.enterprise.aiw.scan.screens.home.composables

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.home_aisle
import aiwscan.composeapp.generated.resources.search_related_products
import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.LocationOn
import androidx.compose.material.icons.filled.Remove
import androidx.compose.material.ripple.LocalRippleTheme
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.IconButtonDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.OutlinedIconButton
import androidx.compose.material3.SheetState
import androidx.compose.material3.Text
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mshopsas.enterprise.aiw.scan.data.basket.Item
import com.mshopsas.enterprise.aiw.scan.data.basket.details.OFFProduct
import com.mshopsas.enterprise.aiw.scan.data.basket.details.ProductDetailsPromo
import com.mshopsas.enterprise.aiw.scan.data.basket.details.ProductDetail
import com.mshopsas.enterprise.aiw.scan.screens.commons.AutoResizeText
import com.mshopsas.enterprise.aiw.scan.screens.commons.FontSizeRange
import com.mshopsas.enterprise.aiw.scan.screens.commons.ImageLoader
import com.mshopsas.enterprise.aiw.scan.screens.commons.LightRippleTheme
import com.mshopsas.enterprise.aiw.scan.screens.commons.NoRippleTheme
import com.mshopsas.enterprise.aiw.scan.theme.BlueGrey
import org.jetbrains.compose.resources.stringResource

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun EditQuantityBottomSheet(
    item: Item,
    detailPromo: ProductDetailsPromo?,
    offProduct: OFFProduct?,
    related: List<ProductDetail>,
    onOK: (Item) -> Unit,
    onCancel: () -> Unit,
    bottomSheetState: SheetState = rememberModalBottomSheetState(skipPartiallyExpanded = false),
) {

    var quantity by remember { mutableStateOf(item.quantity) }

    ModalBottomSheet(
        onDismissRequest = {
            if (item.quantity != quantity)
                onOK(item.copy(quantity = quantity))
            else
                onCancel()
        },
        containerColor = Color.White,
        sheetState = bottomSheetState,
    ) {

        Column(
            modifier = Modifier.fillMaxWidth()
                .padding(start = 32.dp, top = 0.dp, end = 32.dp, bottom = 32.dp),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.spacedBy(32.dp)
        ) {

            // Image and label
            Row {
                ImageLoader(
                    item.image ?: "",
                    modifier = Modifier.weight(1f / 4f).aspectRatio(4 / 4f)
                        .clip(RoundedCornerShape(4.dp))
                )

                Text(
                    text = item.productLabel ?: "",
                    style = MaterialTheme.typography.titleMedium,
                    fontSize = 17.sp,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.weight(3f / 4f).aspectRatio(3 / 1f)
                        .padding(16.dp)
                )
            }

            // Increase or decrease quantity
            Row(verticalAlignment = Alignment.CenterVertically) {

                Spacer(modifier = Modifier.width(8.dp))

                CompositionLocalProvider(LocalRippleTheme provides if (quantity > 0) LocalRippleTheme.current else NoRippleTheme) {
                    OutlinedIconButton(
                        onClick = {
                            if (quantity > 1) {
                                quantity -= 1
                            } else {
                                onOK(item.copy(quantity = 0))
                            }
                        },
                        modifier = Modifier.size(72.dp),
                        border = BorderStroke(2.dp, BlueGrey)
                    ) {
                        Icon(
                            if (quantity > 1) Icons.Default.Remove else Icons.Default.Delete,
                            null,
                            tint = BlueGrey,
                            modifier = Modifier.size(32.dp),
                        )
                    }
                }

                Spacer(modifier = Modifier.width(16.dp))

                AutoResizeText(
                    text = "$quantity",
                    fontSizeRange = FontSizeRange(14.sp, 40.sp),
                    style = MaterialTheme.typography.bodyMedium,
                    textAlign = TextAlign.Center,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.weight(1f)
                )

                Spacer(modifier = Modifier.width(16.dp))

                CompositionLocalProvider(LocalRippleTheme provides LightRippleTheme) {
                    IconButton(
                        onClick = {
                            quantity += 1
                        },
                        colors = IconButtonDefaults.iconButtonColors(containerColor = BlueGrey),
                        modifier = Modifier.size(72.dp)
                    ) {
                        Icon(
                            Icons.Default.Add,
                            null,
                            tint = Color.White,
                            modifier = Modifier.size(32.dp),
                        )
                    }
                }

                Spacer(modifier = Modifier.width(8.dp))
            }

            if (item.aisle != null) {
                Row {
                    Image(Icons.Default.LocationOn, null)
                    Spacer(modifier = Modifier.width(4.dp))
                    Text(stringResource(Res.string.home_aisle, item.aisle))
                }
            }

            if (detailPromo != null)
                PromoInfo(detailPromo)

            OffInfo(offProduct)

            if(related.isNotEmpty()){
                Row(
                    horizontalArrangement = Arrangement.Start
                ) {
                    Text(stringResource(Res.string.search_related_products))
                }
                Column(
                    modifier = Modifier.verticalScroll(rememberScrollState()),
                    horizontalAlignment = Alignment.Start,
                    verticalArrangement = Arrangement.spacedBy(10.dp)
                ) {
                    related.forEach { product ->
                        ProductRelatedMiniCellView(
                            product = product,
                            isAnonymous = true,
                            onItemClick = {}
                        )
                    }
                }
            }

        }
    }
}
