package com.mshopsas.enterprise.aiw.scan.screens.home

import cafe.adriel.voyager.core.model.screenModelScope
import co.touchlab.kermit.Logger
import com.mshopsas.enterprise.aiw.scan.data.Repository
import com.mshopsas.enterprise.aiw.scan.data.basket.Basket
import com.mshopsas.enterprise.aiw.scan.data.basket.Cell
import com.mshopsas.enterprise.aiw.scan.data.basket.Item
import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerData
import com.mshopsas.enterprise.aiw.scan.network.Resource
import com.mshopsas.enterprise.aiw.scan.network.request.SetQuantityItemRequest
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.BaseScreenModel
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.launch

class HomeScreenModel(
    private val repository: Repository
) : BaseScreenModel<HomeContract.Event, HomeContract.State, HomeContract.Effect>() {

    override fun setInitialState() =
        HomeContract.State(
            result = null,
            items = listOf(),
            count = 0,
            total = 0,
            editQuantityItem = null,
            isLoading = false,
            error = "",
            unknownProduct = null,
            userName = "",
            isAnonymous = false,
            showPaymentSheet = false,
            editQuantityItemDetailPromo = null,
            editQuantityItemOff = null,
            itemInPromo = null,
            itemInPromoPromo = null,
            hasBrochure = false,
            related = listOf(),
            hasCompareList = false,
            compareProgress = 0.25f
        )

    override fun handleEvents(event: HomeContract.Event) {
        when (event) {
            HomeContract.Event.OnBackClick -> onBackClick()
            HomeContract.Event.OnNextClick -> onNextClick()
            is HomeContract.Event.OnResult -> onResult(event.result)
            is HomeContract.Event.OnItemClick -> onItemClick(event.item)
            is HomeContract.Event.OnEditQuantityOk -> onEditQuantityOk(event.item)
            HomeContract.Event.OnEditQuantityCancel -> onEditQuantityCancel()
            HomeContract.Event.OnUnknownClose -> onUnknownClose()
            HomeContract.Event.OnPayClick -> onPayClick()
            HomeContract.Event.OnPaymentPerform -> onPaymentPerform()
            HomeContract.Event.OnPaymentClose -> onPaymentClose()
            HomeContract.Event.OnPromoClose -> onPromoClose()
            HomeContract.Event.OnSearchClick -> onSearchClick()
            HomeContract.Event.OnBrochureClick -> onBrochureClick()
            HomeContract.Event.OnHistoricClick -> onHistoricClick()
            HomeContract.Event.OnClearCompareListClick -> onClearShoppingListClick()
            HomeContract.Event.OnShowCompareListClick -> onShowCompareListClick()
        }
    }

    private var detailJob: Job? = null

    init {
        initialize()
    }

    private fun initialize() {
        screenModelScope.launch {
            repository.getBasket().combine(repository.getCompareList()) { b, c ->
                val tempCellList = mutableListOf<Cell>()
                var totalMatches = 0
                b.cellList.forEach { cell ->
                    val tempItemList = mutableListOf<Item>()
                    cell.itemList.forEach { item ->
                        if( c?.items?.any { it.eanCode == item.codeToCompare() } == true ) {
                            tempItemList.add(
                                item.copy(inList = true)
                            )
                            totalMatches += 1
                        } else {
                            tempItemList.add(
                                item.copy(inList = false)
                            )
                        }
                    }
                    tempCellList.add(cell.copy(itemList = tempItemList))
                }
                setState {
                    copy(
                        hasCompareList = !c?.items.isNullOrEmpty(),
                        compareProgress = c?.totalItems?.let { totalMatches.toFloat() / it.toFloat() } ?: 0f
                    )
                }
                b.copy(cellList = tempCellList)
            }.collect { basket ->
                setState {
                    copy(
                        items = basket.cellList,
                        total = basket.amountTotal,
                        count = basket.nbItems,
                        userName = basket.shopperName,
                        isAnonymous = basket.isAnonymous()
                    )
                }
            }
            setState {
                copy(
                    hasBrochure = repository.getCheckIn()?.urlCatalog?.isNotEmpty() ?: false,
                )
            }

        }
    }

    private fun onBackClick() {
        setEffect { HomeContract.Effect.Navigation.ToBack }
    }

    private fun onNextClick() {
        setEffect { HomeContract.Effect.Navigation.ToScan }
    }

    private fun onSearchClick() {
        setEffect { HomeContract.Effect.Navigation.ToSearch }
    }

    private fun onBrochureClick() {
        setEffect { HomeContract.Effect.Navigation.ToBrochure(
            repository.getCheckIn()?.urlCatalog ?: ""
        ) }
    }

    private fun onHistoricClick() {
        setEffect { HomeContract.Effect.Navigation.ToHistoric }
    }

    private fun onClearShoppingListClick() {
        repository.setCompareList(null)
    }

    private fun onShowCompareListClick() {
        //ToDo: show compareList items
    }

    private fun onResult(data: ScannerData?) {
        if (data != null) {

            if ("""^2(3|9|70)\d+""".toRegex().matches(data.data)) {
                linkLoyalty(data.data)
            } else {

                var item: Item? = null
                run loop@{
                    viewState.value.items.forEach { cell ->
                        cell.itemList.forEach {
                            if (it.eanCode == data.data) {
                                item = it
                                return@loop
                            }
                        }
                    }
                }

                val itemRequest = item?.let {
                    SetQuantityItemRequest.fromEdit(it).copy(quantity = it.quantity.plus(1))
                }
                    ?: SetQuantityItemRequest.fromScan(data.data)

                setQuantity(itemRequest)
            }
        }
    }

    private fun setQuantity(item: SetQuantityItemRequest? = null) {
        screenModelScope.launch {
            repository.callSetQuantity(item).collect { result ->
                when (result.status) {
                    Resource.Status.SUCCESS -> {
                        result.data?.let { data ->
                            val basket = data.basket.copy(cellList = data.basket.cellList
                                .filter { it.typeCell != Cell.UNAVAILABLE })
                            repository.setBasket(basket)

                            evaluateBasket(
                                basket, item?.eanCode?.takeIf { data.errorProducts.isNotEmpty() })
                        }

                        setState { copy(isLoading = false) }
                    }

                    Resource.Status.ERROR -> {
                        val errorMsg = result.error?.message ?: "unknown error"
                        setState { copy(isLoading = false, error = errorMsg) }
                    }

                    Resource.Status.LOADING -> {
                        setState { copy(isLoading = true) }
                    }
                }
            }
        }
    }

    private fun evaluateBasket(
        basket: Basket,
        unknownProductCode: String?
    ) {
        val itemCandidate = basket.cellList.getOrNull(0)
            ?.takeIf { it.typeCell == Cell.NORMAL }?.itemList?.getOrNull(0)
            ?.takeIf {
                !it.candidatePromo.isNullOrEmpty() &&
                        !repository.getCandidateList().contains(it.candidatePromo)
            }

        Logger.d { "itemCandidate: $itemCandidate" }

        if (itemCandidate != null) {
            getPromoAlertInfo(itemCandidate)
        } else if (unknownProductCode != null) {
            repository.playError()
            setState { copy(unknownProduct = unknownProductCode) }
        }

        repository.clearCandidateList()
        basket.cellList.forEach { cl ->
            cl.itemList.forEach { il ->
                il.candidatePromo?.let { repository.addCandidate(it) }
            }
        }
    }

    private fun linkLoyalty(shopperLoyaltyCard: String) {
        screenModelScope.launch {
            repository.callLinkLoyalty(shopperLoyaltyCard).collect { result ->
                when (result.status) {
                    Resource.Status.SUCCESS -> {
                        result.data?.let { data ->
                            repository.saveLoyaltyData(data.response)
                        }

                        setQuantity()
                    }

                    Resource.Status.ERROR -> {
                        val errorMsg = result.error?.message ?: "unknown error"
                        setState { copy(isLoading = false, error = errorMsg) }
                    }

                    Resource.Status.LOADING -> {
                        setState { copy(isLoading = true) }
                    }
                }
            }
        }
    }

    private fun onItemClick(item: Item) {
        setState { copy(editQuantityItem = item) }

        detailJob = screenModelScope.launch {
            repository.callProductDetails(item.eanCode).collect { result ->
                if (result.status == Resource.Status.SUCCESS) {
                    val promo = result.data?.productDetail?.discountImageCodeList?.getOrNull(0)
                    setState {
                        copy(
                            editQuantityItemDetailPromo = promo,
                        )
                    }
                }
            }

//            repository.callProductRelated(item.eanCode, item.idFamily).collect { result ->
            repository.callProductRelated(
                eanCode = item.eanCode,
                family = 1,
                quantity = 5,
                mocked = true).collect { result ->
                if (result.status == Resource.Status.SUCCESS) {
                    val products = result.data?.productList
                    setState {
                        copy(
                            related = products ?: listOf()
                        )
                    }
                }
            }

            repository.callOff(item.eanCode).collect { result ->
                if (result.status == Resource.Status.SUCCESS) {
                    val off = result.data?.product
                    setState {
                        copy(
                            editQuantityItemOff = off,
                        )
                    }
                }
            }
        }
    }

    private fun onEditQuantityOk(item: Item) {
        detailJob?.cancel()
        setState {
            copy(
                editQuantityItem = null,
                editQuantityItemDetailPromo = null,
                editQuantityItemOff = null,
                related = listOf()

            )
        }

        val itemRequest = SetQuantityItemRequest.fromEdit(item)
        setQuantity(itemRequest)
    }

    private fun onEditQuantityCancel() {
        setState {
            copy(
                editQuantityItem = null,
                editQuantityItemDetailPromo = null,
                editQuantityItemOff = null,
                related = listOf()
            )
        }
    }

    private fun onUnknownClose() {
        setState { copy(unknownProduct = null) }
    }

    private fun onPayClick() {
        setState { copy(showPaymentSheet = true) }
    }

    private fun onPaymentPerform() {
        setState { copy(showPaymentSheet = false) }
        screenModelScope.launch {
            repository.callEndBasket().collect { result ->
                when (result.status) {
                    Resource.Status.SUCCESS -> {
                        result.data?.let {
                            Logger.d("Response $it")
                            when (it.result) {
                                Basket.WAITING_TO_PAY -> {
                                    setEffect { HomeContract.Effect.Navigation.ToPayments }
                                }

                                Basket.AUDIT_REQUIRED -> {
                                    setEffect { HomeContract.Effect.Navigation.ToAudit(it.auditCode) }
                                }

                                Basket.WAITING_ASSISTANCE -> {
//                                    setEffect { HomeContract.Effect.Navigation.ToAudit }
                                }
                            }
                            setState { copy(isLoading = false) }
                        }
                    }

                    Resource.Status.ERROR -> {
//                        val errorMsg = result.error?.message ?: "unknown error"
                        setState { copy(isLoading = false) }
                    }

                    Resource.Status.LOADING -> {
                        setState { copy(isLoading = true) }
                    }
                }
            }
        }
    }

    private fun onPaymentClose() {
        setState { copy(showPaymentSheet = false) }
    }

    private fun getPromoAlertInfo(item: Item) {
        repository.playAlert()

        setState { copy(itemInPromo = item) }
        detailJob = screenModelScope.launch {
            repository.callProductDetails(item.eanCode).collect { result ->
                if (result.status == Resource.Status.SUCCESS) {
                    val promo = result.data?.productDetail?.discountImageCodeList?.getOrNull(0)
                    setState { copy(itemInPromoPromo = promo) }
                }
            }
        }
    }

    private fun onPromoClose() {
        detailJob?.cancel()
        setState {
            copy(
                itemInPromo = null,
                itemInPromoPromo = null,
                related = listOf()
            )
        }
    }

}
