package com.mshopsas.enterprise.aiw.scan.screens.historic.detail

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.home_aisle
import aiwscan.composeapp.generated.resources.home_aisle_order
import aiwscan.composeapp.generated.resources.search_related_products
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.LocationOn
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mshopsas.enterprise.aiw.scan.data.basket.HistoryOrder
import com.mshopsas.enterprise.aiw.scan.screens.commons.BackButton
import com.mshopsas.enterprise.aiw.scan.screens.commons.ImageLoader
import com.mshopsas.enterprise.aiw.scan.screens.commons.MScanScaffold
import com.mshopsas.enterprise.aiw.scan.screens.home.composables.OffInfo
import com.mshopsas.enterprise.aiw.scan.screens.home.composables.Price
import com.mshopsas.enterprise.aiw.scan.screens.home.composables.PromoInfo
import com.mshopsas.enterprise.aiw.scan.screens.search.SearchProductContract
import com.mshopsas.enterprise.aiw.scan.screens.search.composables.ProductSearchCellView
import org.jetbrains.compose.resources.stringResource

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun HistoryOrderDetailView(
    state: HistoryOrderDetailContract.State,
    onEventSent: (HistoryOrderDetailContract.Event) -> Unit,
    order: HistoryOrder
) {

    LaunchedEffect("Init") {
        onEventSent(HistoryOrderDetailContract.Event.OnInit(order))
    }

    MScanScaffold(
        navigationIcon = {
            BackButton( onClick= {onEventSent(HistoryOrderDetailContract.Event.OnBack)})
        }
    ) { padding ->
        Column(
            modifier = Modifier.fillMaxSize().padding(padding),
        ) {
            LazyColumn(modifier = Modifier.fillMaxSize()) {
                items(state.items) { product ->
                    ProductSearchCellView(
                        product = product.productInfo,
                        isAnonymous = state.isAnonymous,
                        onItemClick = { },
                        modifier = Modifier.animateItemPlacement()
                    )}
            }
        }
    }

}
