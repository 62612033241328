package com.mshopsas.enterprise.aiw.scan.screens.search.detail

import cafe.adriel.voyager.core.model.screenModelScope
import co.touchlab.kermit.Logger
import com.benasher44.uuid.uuid4
import com.mshopsas.enterprise.aiw.scan.data.Repository
import com.mshopsas.enterprise.aiw.scan.data.basket.Basket
import com.mshopsas.enterprise.aiw.scan.data.basket.Cell
import com.mshopsas.enterprise.aiw.scan.data.basket.Item
import com.mshopsas.enterprise.aiw.scan.data.basket.details.ProductDetail
import com.mshopsas.enterprise.aiw.scan.network.Resource
import com.mshopsas.enterprise.aiw.scan.network.request.CheckInRequest
import com.mshopsas.enterprise.aiw.scan.network.response.BasketStatusResponse
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.BaseScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.home.HomeContract
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.launch

class SearchProductDetailScreenModel(
    private val repository: Repository
) : BaseScreenModel<SearchProductDetailContract.Event, SearchProductDetailContract.State, SearchProductDetailContract.Effect>() {

    override fun setInitialState() = SearchProductDetailContract.State(
        promo = null,
        off = null,
        related = listOf()
    )

    override fun handleEvents(event: SearchProductDetailContract.Event) {
        when (event) {
            is SearchProductDetailContract.Event.OnInit -> onInit(event.product)
            SearchProductDetailContract.Event.OnBack -> onBack()
        }
    }

    private fun onInit(product: ProductDetail) {
         screenModelScope.launch {
            repository.callProductDetails(product.eanCode).collect { result ->
                if (result.status == Resource.Status.SUCCESS) {
                    val promo = result.data?.productDetail?.discountImageCodeList?.getOrNull(0)
                    setState {
                        copy(
                            promo = promo,
                        )
                    }
                }
            }

            repository.callOff(product.eanCode).collect { result ->
                if (result.status == Resource.Status.SUCCESS) {
                    val off = result.data?.product
                    setState {
                        copy(
                            off = off,
                        )
                    }
                }
            }

            if(product.idFamily != null) {
                repository.callProductRelated(
                    eanCode = product.eanCode,
                    family = product.idFamily
                ).collect { result ->
                    if (result.status == Resource.Status.SUCCESS) {
                        val products = result.data?.productList
                        setState {
                            copy(
                                related = products ?: listOf()
                            )
                        }
                    }
                }
            }
        }
    }

    private fun onBack() {
        setEffect { SearchProductDetailContract.Effect.Navigation.ToBack }
    }

}
