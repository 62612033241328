package com.mshopsas.enterprise.aiw.scan.screens.home

import com.mshopsas.enterprise.aiw.scan.data.basket.Cell
import com.mshopsas.enterprise.aiw.scan.data.basket.Item
import com.mshopsas.enterprise.aiw.scan.data.basket.details.OFFProduct
import com.mshopsas.enterprise.aiw.scan.data.basket.details.ProductDetailsPromo
import com.mshopsas.enterprise.aiw.scan.data.basket.details.ProductDetail
import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerData
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewEvent
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewSideEffect
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewState

class HomeContract {

    sealed interface Event : ViewEvent {
        data object OnBackClick : Event
        data object OnNextClick : Event
        data class OnResult(val result: ScannerData?) : Event
        data class OnItemClick(val item: Item) : Event
        data object OnEditQuantityCancel : Event
        data class OnEditQuantityOk(val item: Item) : Event
        data object OnUnknownClose : Event
        data object OnPayClick : Event
        data object OnPaymentPerform : Event
        data object OnPaymentClose : Event
        data object OnPromoClose : Event
        data object OnSearchClick: Event
        data object OnBrochureClick: Event
        data object OnHistoricClick: Event
        data object OnClearCompareListClick: Event
        data object OnShowCompareListClick: Event
    }

    data class State(
        val result: ScannerData?,
        val items: List<Cell>,
        val count: Int,
        val total: Int,
        val editQuantityItem: Item?,
        val isLoading: Boolean,
        val error: String,
        val unknownProduct: String?,
        val userName: String,
        val isAnonymous: Boolean,
        val showPaymentSheet: Boolean,
        val editQuantityItemDetailPromo: ProductDetailsPromo?,
        val editQuantityItemOff: OFFProduct?,
        val itemInPromo: Item?,
        val itemInPromoPromo: ProductDetailsPromo?,
        val hasBrochure: Boolean,
        val related: List<ProductDetail>,
        val hasCompareList: Boolean,
        val compareProgress: Float
    ) : ViewState

    sealed interface Effect : ViewSideEffect {

        sealed interface Navigation : Effect {
            data object ToBack : Navigation
            data object ToScan : Navigation
            data object ToPayments : Navigation
            data class ToAudit(val auditCode: String) : Navigation
            data object ToSearch : Navigation
            data class ToBrochure(val url: String) : Navigation
            data object ToHistoric : Navigation
        }
    }

}
